<template>
  <div id="ReliabilityPanel">
    <feature-header name="Reliability" :score="vehicle.Reliability_Score" />
    <BasicFeaturePanel :features="features" icon-name="reliability_icon.svg"/>
  </div>
</template>

<script>
import common from "Mixins/common";
const BasicFeaturePanel = () => import("Components/VehicleDetail/BasicFeaturePanel");
const FeatureHeader = () => import("Components/VehicleDetail/FeatureHeader");
export default {
  props: {
    vehicle: Object,
  },
  computed: {
    features() {
      const basicWarranty = this.vehicle.Basic_Warranty_Years
        ? `${this.vehicle.Basic_Warranty_Years} years or ${common.toNumber(
            this.vehicle.Basic_Warranty_Miles
          )} miles`
        : "N/A";
      const batteryWarranty = this.vehicle.Battery_Warranty_Years
        ? `${this.vehicle.Battery_Warranty_Years} years or ${common.toNumber(
            this.vehicle.Battery_Warranty_Miles
          )} miles`
        : "N/A";
      return [
        { label: "Basic warranty", value: basicWarranty },
        {
          label: "Battery warranty",
          value: batteryWarranty,
        },
        { label: "NHTSA complaints", value: this.vehicle.NHTSA_Complaints || 'N/A' },
        { label: "NHTSA recalls", value: this.vehicle.NHTSA_Recalls || 'N/A' },
      ];
    },
  },
  components: {
    FeatureHeader,
    BasicFeaturePanel
},
};
</script>
